<script>
import set from 'lodash/set'
export default {
  methods: {
    updateFormValue (data) {
      set(this, data.key, data.value)
    }
  }
}
</script>
