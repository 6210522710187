// Due to the change in incidents on the central/client, we are not using this component.
<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewIncidentForm"
        ref="addNewIncidentForm"
        v-model="isFormValid"
        @submit.prevent="submitNewIncidentForm"
      >
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <ChooseClientPartial
              ref="chooseClientPartial"
              :client="{ key:'incident.clientId', value:incident.clientId }"
              @update="updateFormValue"
            />
          </v-col>

          <v-col cols="12">
            <v-menu
              v-model="showDatePicker"
              v-bind="attributes.menu"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDate"
                  :rules="showDatePicker ? [] : [rules.required]"
                  label="Data realizacji"
                  placeholder="Wybierz datę realizacji"
                  v-bind="{...attrs, ...attributes.textField}"
                  v-on="on"
                >
                  <template #prepend-inner>
                    <Icon
                      name="date"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                  <template #append>
                    <SwitchDayButtons
                      v-model="incident.reportedAt"
                      :min="minDate"
                    />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="incident.reportedAt"
                v-bind="attributes.datePicker"
                :rules="showDatePicker ? [] : [rules.required]"
                :min="minDate"
                @input="showDatePicker = false"
              />
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Osoba zgłaszająca"
              v-model="incident.contactName"
              :rules="[rules.required]"
              placeholder="Podaj imię i nazwisko osoby zgłaszającej"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              :rows="2"
              auto-grow
              :rules="[rules.required]"
              label="Wiadomość klienta"
              v-model="incident.text"
              placeholder="Wpisz wiadomość klienta"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Numer telefonu"
              v-model="incident.phoneNumber"
              :rules="[rules.required, rules.phoneNumber]"
              v-mask="getFieldMask('phoneNumber')"
              placeholder="Wpisz numer telefonu"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="filters.incidentSources"
              label="Kanał"
              placeholder="Wybierz kanał pochodzenia komunikacji"
              v-model="incident.incidentSourceId"
              item-value="id"
              item-text="name"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              dense
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              :rows="2"
              auto-grow
              label="Dodatkowe informacje"
              v-model="incident.notes"
              placeholder="Wpisz dodatkowe informacje"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewIncidentForm"
      >
        Dodaj incydent
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DateTime from 'luxon/src/datetime'
import rules from '../../utils/validators'
import { dateStringFormat, getFieldMask } from '../../utils'
import attributes from '../../const/datePickerAttrributes'
import updateFormValue from '../../mixins/updateFormValue.vue'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import ChooseClientPartial from './Partials/ChooseClient'

export default {
  components: {
    ChooseClientPartial,
    DialogFormWrapper
  },
  mixins: [updateFormValue],
  data () {
    return {
      rules,
      attributes,
      isFormValid: true,
      showDatePicker: false,
      incident: {
        clientId: null,
        reportedAt: new Date().toISOString().substr(0, 10),
        incidentSourceId: null,
        text: null,
        notes: null,
        phoneNumber: null
      }
    }
  },
  computed: {
    ...mapState({
      filters: state => state.core.filters,
      isProcessing: state => state.incidents.isProcessing
    }),
    formattedDate () {
      if (this.incident.reportedAt) {
        return dateStringFormat(this.incident.reportedAt)
      }
      return ''
    },
    minDate () {
      return DateTime.utc().toISODate()
    }
  },
  methods: {
    ...mapActions({
      addNewIncident: 'incidents/addNewItem',
      closeDialog: 'layout/closeDialog'
    }),
    getFieldMask,
    submitNewIncidentForm () {
      this.$refs.addNewIncidentForm.validate()
      if (this.$refs.chooseClientPartial.$refs.newClientPartial) {
        this.$refs.chooseClientPartial
          .$refs.newClientPartial
          .$refs.addNewClientForm
          .validate()
      }
      if (this.isFormValid) {
        this.addNewIncident({ params: this.incident })
          .then(() => {
            this.closeDialog()
          })
      }
    }
  }
}
</script>
