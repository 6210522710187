import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"py-0 mt-n2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end"},[_c(VSpacer),_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"input-label"},[_vm._v(" Nowy klient ")]),_c(VSwitch,{staticClass:"ml-2 my-0 pa-0",attrs:{"hide-details":""},model:{value:(_vm.showNewClientForm),callback:function ($$v) {_vm.showNewClientForm=$$v},expression:"showNewClientForm"}})],1)],1)]),_c(VCol,{staticClass:"mt-n4"},[_c(VRow,[(_vm.showNewClientForm)?_c(VCol,{attrs:{"cols":"12"}},[_c('ClientForm',{ref:"newClientPartial",attrs:{"actions":""},on:{"submitClientForm":_vm.submitClientForm,"close":function($event){_vm.showNewClientForm = false}}})],1):_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"outlined":"","items":_vm.clientsList,"label":"Klient","value":_vm.client.value,"rules":[_vm.rules.required],"item-text":"name","item-value":"id","placeholder":"Szukaj klienta","append-icon":"mdi-chevron-down","dense":""},on:{"input":function($event){return _vm.emitUpdate(_vm.client.key, $event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('Icon',{staticClass:"mr-2 mt-1",attrs:{"name":"search","size":"small"}})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.labeled && item.purchaseLimitExceeded)?[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"grey--text d-inline-block ml-2"},[_vm._v(" - przekroczono limit")])]:[_vm._v(" "+_vm._s(item.name)+" ")]]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }