<template>
  <v-row>
    <v-col
      cols="12"
      class="py-0 mt-n2"
    >
      <div class="d-flex justify-end">
        <v-spacer />
        <div class="d-flex align-center">
          <span class="input-label">
            Nowy klient
          </span>
          <v-switch
            v-model="showNewClientForm"
            hide-details
            class="ml-2 my-0 pa-0"
          />
        </div>
      </div>
    </v-col>
    <v-col class="mt-n4">
      <v-row>
        <v-col
          cols="12"
          v-if="showNewClientForm"
        >
          <ClientForm
            ref="newClientPartial"
            actions
            @submitClientForm="submitClientForm"
            @close="showNewClientForm = false"
          />
        </v-col>

        <v-col
          v-else
          cols="12"
        >
          <v-autocomplete
            outlined
            :items="clientsList"
            label="Klient"
            :value="client.value"
            @input="emitUpdate(client.key, $event)"
            :rules="[rules.required]"
            item-text="name"
            item-value="id"
            placeholder="Szukaj klienta"
            append-icon="mdi-chevron-down"
            dense
          >
            <template #prepend-inner>
              <Icon
                name="search"
                size="small"
                class="mr-2 mt-1"
              />
            </template>
            <template #item="{item}">
              <template v-if="labeled && item.purchaseLimitExceeded">
                {{ item.name }} <span class="grey--text d-inline-block ml-2"> - przekroczono limit</span>
              </template>
              <template v-else>
                {{ item.name }}
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ClientForm from './ClientForm'
import { mapState, mapActions } from 'vuex'
import rules from '../../../utils/validators'

export default {
  components: {
    ClientForm
  },
  props: {
    client: {
      type: Object,
      default: () => {}
    },
    labeled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules,
      showNewClientForm: false
    }
  },
  computed: {
    ...mapState({
      clientsList: state => state.clients.simplifiedList
    })
  },
  created () {
    this.fetchClients()
  },
  methods: {
    ...mapActions({
      addNewClient: 'clients/addNewItem',
      showSnackbar: 'snackbar/showSnackbar',
      getMiddlemen: 'middlemen/getItems',
      getSimplifiedClients: 'clients/getSimplifiedItems'
    }),
    fetchClients () {
      this.getSimplifiedClients({ params: { swapOrPickup: false, filters: { withoutPatron: true } } })
    },
    emitUpdate (key, value) {
      this.$emit('update', { key, value })
    },
    submitClientForm (params) {
      this.addNewClient({ params })
        .then((resp) => {
          if (resp.isMiddleman) this.getMiddlemen()
          this.clientsList.push(resp)
          this.emitUpdate(this.client.key, resp.id)
          this.showNewClientForm = false
        })
    }
  }
}
</script>
